// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXpMkQG_ThrOWE2NqQIg_y9ymYjsAyiTQ",
  authDomain: "gemini-ai-71659.firebaseapp.com",
  projectId: "gemini-ai-71659",
  storageBucket: "gemini-ai-71659.appspot.com",
  messagingSenderId: "619936342910",
  appId: "1:619936342910:web:a9528eddc50ea891a1892f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);