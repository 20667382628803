import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import MGPDefi from '../src/artifacts/contracts/Rector.sol/Rector.json'
import BackupFunds from '../src/artifacts/contracts/BackupFunds.sol/BackupFunds.json'
import { Homepage, Dashboard, Deposit, Wallet, Teams, SplitAccount, Admin, TeamUsers } from "./components/index";
import { useTimer } from 'react-timer-hook';

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

const Appstate = createContext();

function App() {
  const {ethereum} = window;
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const [show, setShow] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdmin2, setIsAdmin2] = useState(false);
  const [isAdmin3, setIsAdmin3] = useState(false);
  const [price, setPrice] = useState(0);
  const [isMd, setIsMd] = useState(false);
  
  const toggle = () => {
    setShow(!show);
  }

  useEffect(() => {
    const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
    if (isMdScreen) {
      setShow(true);
      setIsMd(true);
    }
  },[])

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };
  
  const usdt = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
  const rtr = "0x5DaBDbc3DbdA9BBEDF5b6c1F839482E8C712e70a";
  const mainAddr = "0xe817F2342acbAdc71398e80545E1155534408fe1";
  const backupFunds = "0xCcF140751D8E87C8A6747e57F51feBF28135Bf53";
  
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, MGPDefi.abi, signer);
  const backup = new ethers.Contract(backupFunds, BackupFunds.abi, signer);
  const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);
  const tokenContract2 = new ethers.Contract(rtr, erc20Abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      if(accounts[0].toLowerCase() == ("0xF2C6378A6E9aC3676156128969DFb15E23e2Fcd5").toLowerCase()) {
        setIsAdmin(true);
      }
      // setWalletAddress("0xB3EE3a804bE30Eb68805eE8c520cd6a8fD7d1897");
      // setWalletAddress("0x120DC3412b1B4a83fe2c21DB5eCD8E1D3cA02A84");
      let _price = await contract.callStatic.getQuoteInUSDT(ethers.utils.parseUnits("1", "mwei"));
      setPrice(convert(_price));
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  const convert = (val) => {
    return Number(ethers.utils.formatUnits(val, "mwei"));
  }

  let lvlName = ["NEWBIE", "SILVER" , "GOLD", "PLATINIUM" ,"EMERALD", "RUBY", "DIAMOND", "DOUBLE-DIAMOND"];

  return (
    <Appstate.Provider value={{mainAddr, contract,tokenContract, backup, backupFunds, price, lvlName, tokenContract2, isAdmin, isAdmin2, isAdmin3, provider ,convert ,formatTime ,walletAddress,setWalletAddress ,setChange, change, toggle, show, setShow, isMd}}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/teamusers" element={<TeamUsers />} />
        <Route path="/split" element={<SplitAccount />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/manage" element={<Admin />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate};
